import React, { useEffect, useLayoutEffect } from "react";

import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { Container } from "~/components/.base/containers";
import { css } from "styled-components";

const LS_RELOAD_FLAG = 'cml-termly-reload-flag'

const TermlyPage = ({ data, location }) => {
  const {
    contentfulPage: { title, termlyScriptUrl, termlyId }
  } = data;

  useLayoutEffect(() => {
    const reloadFlag = window.localStorage.getItem(LS_RELOAD_FLAG);

    if (!reloadFlag) {
      window.localStorage.setItem(LS_RELOAD_FLAG, 'true');
      window.location.reload();
    };

    const script = document.createElement("script");
    script.src = termlyScriptUrl;
    script.id = 'termly-jssdk';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.localStorage.removeItem(LS_RELOAD_FLAG);
    };
  }, [location.pathname]);

  return (
    <>
      <SEO title={title} />
      <DeriveHeaderClasses location={location} />
      <Container
        css={css`
          width: 970px;
          max-width: 100%;
          min-height: 100vh;
        `}
      >
        <div
          name="termly-embed"
          data-id={termlyId}
          data-type="iframe"
        ></div>
      </Container>
    </>
  );
};

export default TermlyPage;
